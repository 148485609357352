<template>
    <mj-what-is />
</template>

<script>
    // @ is an alias to /src
    import whatIs from "@/components/whatIs/WhatIs_components.vue";

    export default {
        name: "whatIs",
        components: {
            mjWhatIs: whatIs
        }
    };
</script>
